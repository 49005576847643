export const WEALTHIEST_DATA = [
    {
        name: "Elon Musk",
        net_worth: "$258.8 billion",
        description: "Elon Musk, the visionary entrepreneur behind Tesla, SpaceX, and other innovative companies like Neuralink and The Boring Company, is widely known for his ambitious goals of revolutionizing industries. His primary source of income comes from his significant holdings in Tesla, the leading electric vehicle company, and SpaceX, which is pioneering space exploration and satellite technology.",
        age: 53,
        country: "United States",
        companies: ["Tesla", "SpaceX"],
        profile: "/wealthiest/elon_musk.jpeg"
    },
    {
        name: "Larry Ellison",
        net_worth: "$206.7 billion",
        description: "Larry Ellison, the co-founder of Oracle, built his fortune by creating one of the world’s largest providers of database and cloud computing solutions. His wealth is primarily derived from his substantial stake in Oracle, where he also serves as the chairman and chief technology officer.",
        age: 79,
        country: "United States",
        company: "Oracle",
        profile: "/wealthiest/larry_ellison.png"
    },
    {
        name: "Mark Zuckerberg",
        net_worth: "$202.2 billion",
        description: "Mark Zuckerberg, the CEO and co-founder of Meta (formerly Facebook), transformed the way people connect globally through platforms like Facebook, Instagram, and WhatsApp. His wealth largely comes from his ownership stake in Meta, making him one of the most influential figures in social media and technology.",
        age: 40,
        country: "United States",
        company: "Meta",
        profile: "/wealthiest/mark_zuckerberg.jpg"
    },
    {
        name: "Jeff Bezos",
        net_worth: "$199.1 billion",
        description: "Jeff Bezos, the founder and former CEO of Amazon, redefined e-commerce and cloud computing through Amazon Web Services. While he has stepped down from active management, his primary source of income remains his ownership in Amazon. He also invests in ventures like Blue Origin and focuses on philanthropy.",
        age: 60,
        country: "United States",
        company: "Amazon"
    },
    {
        name: "Bernard Arnault & family",
        net_worth: "$189.4 billion",
        description: "Bernard Arnault, the chairman and CEO of LVMH, presides over the world’s largest luxury goods conglomerate, which includes brands like Louis Vuitton, Dior, and Moët & Chandon. His wealth is primarily generated from his stake in LVMH, solidifying his position as a titan in the luxury industry.",
        age: 74,
        country: "France",
        company: "LVMH"
    },
    {
        name: "Warren Buffett",
        net_worth: "$142.6 billion",
        description: "Warren Buffett, often called the “Oracle of Omaha,” is a legendary investor and the CEO of Berkshire Hathaway, a conglomerate that owns a diverse portfolio of businesses. His wealth is largely derived from his holdings in Berkshire Hathaway, reflecting his unmatched prowess in value investing.",
        age: 93,
        country: "United States",
        company: "Berkshire Hathaway"
    },
    {
        name: "Larry Page",
        net_worth: "$135.7 billion",
        description: "Larry Page, co-founder of Google and its parent company Alphabet, revolutionized how information is accessed through the world’s leading search engine. His wealth primarily comes from his shares in Alphabet, and he remains involved in forward-looking projects like self-driving cars and healthcare innovation.",
        age: 51,
        country: "United States",
        company: "Google"
    },
    {
        name: "Sergey Brin",
        net_worth: "$129.9 billion",
        description: "Sergey Brin, co-founder of Google and Alphabet, shares the credit for shaping the digital era with innovations in search technology and cloud services. Like his co-founder Larry Page, Brin’s wealth is rooted in his substantial ownership in Alphabet, while he also explores philanthropic and tech initiatives.",
        age: 50,
        country: "United States",
        company: "Google"
    },
    {
        name: "Amancio Ortega",
        net_worth: "$129.1 billion",
        description: "Amancio Ortega, the founder of Inditex, built a global fashion empire that includes Zara and other popular brands. Known for his low-profile lifestyle, Ortega earns his wealth primarily through dividends from Inditex, a leader in fast fashion and retail innovation.",
        age: 88,
        country: "Spain",
        company: "Zara"
    },
    {
        name: "Steve Ballmer",
        net_worth: "$120.8 billion",
        description: "Steve Ballmer, former CEO of Microsoft and current owner of the Los Angeles Clippers, played a pivotal role in Microsoft’s growth during his tenure. His wealth is predominantly sourced from his Microsoft shares, which have grown substantially since his time leading the company.",
        age: 67,
        country: "United States",
        companies: ["Microsoft", "Los Angeles Clippers"]
    },
    {
        name: "Jensen Huang",
        net_worth: "$111.6 billion",
        description: "Jensen Huang, the co-founder and CEO of NVIDIA, has been instrumental in driving advancements in graphics processing units (GPUs) and AI technology. His wealth stems from his significant stake in NVIDIA, which has become a leader in high-performance computing and AI development.",
        age: 61,
        country: "United States",
        company: "Nvidia"
    },
    {
        name: "Mukesh Ambani",
        net_worth: "$108.9 billion",
        description: "Mukesh Ambani, chairman of Reliance Industries, oversees a conglomerate that dominates petrochemicals, telecommunications, and retail in India. His primary source of wealth comes from his ownership in Reliance, which has significantly diversified under his leadership.",
        age: 66,
        country: "India",
        company: "Reliance Industries"
    },
    {
        name: "Bill Gates",
        net_worth: "$105.1 billion",
        description: "Bill Gates, co-founder of Microsoft, built a fortune by creating the world’s largest software company. While he has shifted focus to philanthropy through the Bill & Melinda Gates Foundation, his wealth still largely comes from investments and residual income from Microsoft.",
        age: 68,
        country: "United States",
        companies: ["Microsoft", "Bill & Melinda Gates Foundation"]
    },
    {
        name: "Michael Dell",
        net_worth: "$105.1 billion",
        description: "Michael Dell, the founder and CEO of Dell Technologies, revolutionized the personal computing industry with a direct-to-consumer business model. His wealth is primarily tied to his holdings in Dell Technologies, which continues to thrive in the IT and cloud computing sectors.",
        age: 59,
        country: "United States",
        company: "Dell Technologies"
    },
    {
        name: "Michael Bloomberg",
        net_worth: "$104.7 billion",
        description: "Michael Bloomberg, the founder of Bloomberg L.P., created a financial data and media empire that serves as a cornerstone for global finance. His primary source of income is his majority stake in Bloomberg L.P., which has made him one of the richest individuals in the media industry.",
        age: 82,
        country: "United States",
        company: "Bloomberg LP"
    },
    {
        name: "Rob Walton & family",
        net_worth: "$98.3 billion",
        description: "Rob Walton, the eldest son of Walmart founder Sam Walton, served as the company’s chairman for over two decades. His wealth is derived from his stake in Walmart, the world’s largest retailer, which continues to dominate global markets.",
        age: 79,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Jim Walton & family",
        net_worth: "$97.1 billion",
        description: "Jim Walton, the youngest son of Walmart founder Sam Walton, maintains a significant role in the family’s business interests, including retail and banking. His primary source of income is his substantial stake in Walmart, ensuring the family’s legacy in the retail industry.",
        age: 76,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Alice Walton",
        net_worth: "$89.7 billion",
        description: "Alice Walton, the daughter of Walmart founder Sam Walton, is known for her passion for art and philanthropy. Her wealth comes from her ownership in Walmart, making her one of the wealthiest women in the world.",
        age: 74,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Francoise Bettencourt Meyers & family",
        net_worth: "$88.5 billion",
        description: "Francoise Bettencourt Meyers, the heiress of the L’Oréal fortune, is actively involved in the management of the world’s largest cosmetics company. Her wealth primarily stems from her substantial ownership in L’Oréal, a family legacy that has become a global powerhouse in beauty.",
        age: 70,
        country: "France",
        company: "L'Oréal"
    },
    {
        name: "Carlos Slim Helu & family",
        net_worth: "$88.0 billion",
        description: "Carlos Slim Helu, a Mexican business magnate, built his wealth through holdings in telecommunications, particularly América Móvil. His family continues to manage a diverse portfolio of interests in real estate, retail, and finance, with telecom being the primary income source.",
        age: 84,
        country: "Mexico",
        companies: ["América Móvil", "Grupo Carso"]
    }
]
