import React from 'react';
import {PASTORS} from "./data/pastors";

/**
 * PastorsList
 * Created on 7/8/24
 */
const PastorsList = () => {

    return (
        <div className={"container mx-auto px-4 lg:px-0"}>
            <h1 className={"text-center text-2xl mb-6"}>World's Wealthiest Pastors</h1>

            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2"}>
                {
                    PASTORS.map(pastor => (
                        <div className={"flex gap-2 card"} key={pastor.name}>
                            <div>
                                <div className="flex">
                                    {pastor.profile && <div className={"w-1/3 shrink-0 mr-4"}>
                                        <img src={`${process.env.PUBLIC_URL}/profiles/${pastor.profile}`}
                                             alt={`${pastor.name} profile picture`} className={"w-full rounded-lg"}/>
                                    </div>}

                                    <div>
                                        <h2 className={"text-2xl font-semibold"}>{pastor.name}</h2>
                                        <p>{pastor.country}</p>
                                        <p className={"mt-4"}>{pastor.description}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default PastorsList;
