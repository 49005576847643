import React from 'react';
import {WEALTHIEST_DATA} from "./data";

/**
 * PastorsList
 * Created on 7/8/24
 */
const WealthiestPage = () => {

    const joinCompanies = (companies: string[]) => {
        return companies.join(', ');
    }

    return (
        <div className={"container mx-auto px-4 lg:px-0"}>
            <h1 className={"text-center text-2xl mb-6"}>World's Wealthiest People</h1>

            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2"}>
                {
                    WEALTHIEST_DATA.map(person => (
                        <div className={"flex gap-2 card"} key={person.name}>
                            <div>
                                <div className="flex">
                                    {person.profile && <div className={"w-1/3 shrink-0 mr-4"}>
                                        <img src={`${process.env.PUBLIC_URL}/profiles/${person.profile}`}
                                             alt={`${person.name} profile picture`} className={"w-full rounded-lg"}/>
                                    </div>}

                                    <div>
                                        <h2 className={"text-2xl font-semibold"}>{person.name}</h2>
                                        <p className={"font-bold text-xl"}>{person.net_worth}</p>
                                        <p>{person.country} {
                                            person.companies ? (
                                                <>
                                                    <span>&bull;</span> {joinCompanies(person.companies)}
                                                </>
                                            ) : null}</p>
                                        <p className={"mt-4"}>{person.description}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default WealthiestPage;
